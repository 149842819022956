
// Vue reactivity
import { computed } from 'vue';

// components
import { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
        IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon, IonBackButton,
        IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
        IonButton, IonButtons, } from '@ionic/vue';

// API services
import { utils } from '@/composables/utils';
import { useRoute } from 'vue-router';
import { useI18n } from 'vue-i18n';
import { useStore } from 'vuex';

export default {
  name: 'NotificationDetailPage',
  components: { IonPage, IonHeader, IonToolbar, IonTitle, IonContent,
                IonGrid, IonRow, IonCol, IonSpinner, IonItem, IonLabel, IonIcon, IonBackButton,
                IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle,
                IonButton, IonButtons, },
  setup() {
    const store = useStore();
    const { t } = useI18n();
    const route = useRoute();
    const currId = route.params.id; // post ID

    // 1. declare state variables (ref to make them reactive)
    const loading = computed(() => store.state.loadingNotifications);
    const notification = computed(() => store.getters.getNotificationById(currId));

    // methods or filters
    const parentPath = route.params.parentPath;
    const { formatDate } = utils();
    
    // 3. return variables & methods to be used in template HTML
    return {
      t,

      // icons

      // variables
      currId, parentPath, loading, notification,

      // methods
      formatDate,
    }
  },
}
